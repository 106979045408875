//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';

export default {
  name: 'TwoStepVerificationProgress',
  components: {
    VIcon,
  },

  props: {
    steps: Array,
    activeStepIndex: Number,
  },
}
