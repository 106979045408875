//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

import VInput from '@/components/UI/VInput';

import { getCodeOnEmail } from '@/requestManager/cabinet/verifications';

export default {
  name: 'TwoStepVerificationForthStep',
  components: {
    VInput,
  },

  props: {
    code: String,
    codeError: String,
    codeIsValid: Boolean,
  },
  data: () => ({
    shownSendBtn: true,
    counter: 60,
  }),
  computed: {
    ...mapState('cabinet/profile', [
      'user',
    ]),
  },

  methods: {
    timer() {
      let timer = setInterval(() => {
        if (this.counter > 1) {
          this.shownSendBtn = false;
          this.counter--;
        } else if (this.counter === 1) {
          this.shownSendBtn = true;
          this.counter = 60;
          clearInterval(timer);
        }
      }, 1000);
    },

    async sendConfirmationCode() {
      this.timer();

      try {
        await getCodeOnEmail();
      } catch (e) {
        console.error(e);
      }
    },
  },

  i18n: {
    messages: {
      en: {
        'Title': 'Save the backup key in a safe place',
        'Description': 'Enter the 6-digit code sent to {email}',
        'Label': 'E-mail confirmation code',
        'Enter code': 'Enter code',
        'Send code': 'Send code',
        'Send code again': 'Send code again in {counter}',
        'Less then': 'The code is less than 6 characters!',
        'Empty': 'The field should not be empty',
      },
      ru: {
        'Title': 'Сохраните резервный ключ в надежном месте',
        'Description': 'Введите 6-значный код, отправленный на {email}',
        'Label': 'Код подтверждения e-mail',
        'Enter code': 'Введите код',
        'Send code': 'Получить код',
        'Send code again': 'Отправить код снова {counter}',
        'Less then': 'Код меньше 6 символов!',
        'Empty': 'Поле не должно быть пустым!',
      },
    },
  },
}
