import { render, staticRenderFns } from "./TwoStepVerificationSecondStep.vue?vue&type=template&id=2cc87272&scoped=true&"
import script from "./TwoStepVerificationSecondStep.vue?vue&type=script&lang=js&"
export * from "./TwoStepVerificationSecondStep.vue?vue&type=script&lang=js&"
import style0 from "./TwoStepVerificationSecondStep.vue?vue&type=style&index=0&id=2cc87272&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cc87272",
  null
  
)

export default component.exports