//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TwoStepVerificationThirdStep',

  props: {
    verification: Object,
  },

  i18n: {
    messages: {
      en: {
        'Title': 'Save the backup key in a safe place',
        'Description': '*This key will allow you to restore your Authenticator in case of phone loss.\n' +
          'Otherwise, resetting Google Authenticator will take at least 7 days.',
      },
      ru: {
        'Title': 'Сохраните резервный ключ в надежном месте',
        'Description': '*Этот ключ позволит вам восстановить ваш Authenticator в случае потери телефона.\n' +
          'В противном случае сброс Google Authenticator займет не менее 7 дней.',
      },
    },
  },
}
