//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

import VIcon from '@/components/UI/VIcon';

import TwoStepVerificationProgress from '@/components/System/Modals/TwoStepVerification/TwoStepVerificationProgress';
import TwoStepVerificationFirstStep
  from '@/components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationFirstStep';
import TwoStepVerificationSecondStep
  from '@/components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationSecondStep';
import TwoStepVerificationThirdStep
  from '@/components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationThirdStep';
import TwoStepVerificationFinalStep
  from '@/components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationFinalStep';
import TwoStepVerificationForthStep
  from '@/components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationForthStep';
import TwoStepVerificationControl from '@/components/System/Modals/TwoStepVerification/TwoStepVerificationControl';

import { STEPS } from '@/utils/constants/verification';

import { checkEmailCode } from '@/requestManager/cabinet/verifications';

export default {
  name: 'TwoStepVerificationModal',
  components: {
    VIcon,
    TwoStepVerificationProgress,
    TwoStepVerificationFirstStep,
    TwoStepVerificationSecondStep,
    TwoStepVerificationThirdStep,
    TwoStepVerificationForthStep,
    TwoStepVerificationFinalStep,
    TwoStepVerificationControl,
  },

  data() {
    return {
      STEPS,

      code: null,
      codeIsValid: true,
      codeError: '',

      steps: [
        {
          id: STEPS.FIRST,
          label: this.$t('Download app'),
          completed: false,
        },
        {
          id: STEPS.SECOND,
          label: this.$t('Scan QR code'),
          completed: false,
        },
        {
          id: STEPS.THIRD,
          label: this.$t('Backup key'),
          completed: false,
        },
        {
          id: STEPS.FOURTH,
          label: this.$t('Google auth'),
          completed: false,
        },
        {
          id: STEPS.FIFTH,
          label: this.$t('Final'),
          completed: false,
        },
      ],
      activeStepIndex: STEPS.FIRST,
    }
  },
  computed: {
    ...mapState('cabinet/verifications', [
      'twoFAVerification',
    ]),

    nextIsEnabled() {
      if (this.activeStepIndex === STEPS.FOURTH) {
        return this.code?.length === 6;
      } else {
        return true;
      }
    },
  },

  methods: {
    resetStepsAfterClose() {
      this.code = null;
      this.codeIsValid = true;
      this.codeError = '';

      this.activeStepIndex = STEPS.FIRST;
      this.steps.forEach(step => {
        step.completed = false;
      });
    },
    nextStep() {
      this.steps[this.activeStepIndex].completed = true;
      this.activeStepIndex++;
    },
    prevStep() {
      this.activeStepIndex--;
      this.steps[this.activeStepIndex].completed = false;
    },

    async sendEmailCode() {
      try {
        const response = await checkEmailCode(this.code);

        if (response.data.success) {
          this.activeStepIndex++;
        } else {
          this.codeError = response.data.message;
          this.codeIsValid = response.data.success;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },

  i18n: {
    messages: {
      en: {
        'Google auth': 'Enable Google Authenticator',
        'Download app': 'Download app',
        'Scan QR code': 'Scan QR-code',
        'Backup key': 'Backup key',
        'Final': 'Final',
      },
      ru: {
        'Google auth': 'Включите Google Authenticator',
        'Download app': 'Скачайте приложение',
        'Scan QR code': 'Сканировать QR-код',
        'Backup key': 'Резервный ключ',
        'Final': 'Завершить',
      },
    },
  },
}
