//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VButton from '@/components/UI/VButton';

import { V_BUTTON } from '@/utils/constants/UI';

export default {
  name: 'TwoStepVerificationFirstStep',
  components: {
    VButton,
  },

  data: () => ({
    V_BUTTON,
  }),

  i18n: {
    messages: {
      en: {
        'Title': 'Download and install the Google Authenticator app',
        'Subtitle': 'QR code to download',
        'Description': 'Scan the code with your phone\'s camera or through a browser',
        'Button': 'How do I scan a QR code?',
      },
      ru: {
        'Title': 'Загрузите и установите приложение Google Authenticator',
        'Subtitle': 'QR-код для загрузки',
        'Description': 'Отсканируйте код камерой телефона или через браузер',
        'Button': 'Как отсканировать QR- код?',
      },
    },
  },
}
