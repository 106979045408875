//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TwoStepVerificationFinalStep',

  i18n: {
    messages: {
      en: {
        'Title': 'Final',
        'Congratulations': 'Congratulate',
        'Description': 'You have successfully enabled 2FA!',
      },
      ru: {
        'Title': 'Завершение',
        'Congratulations': 'Поздравляем',
        'Description': 'Вы успешно подключили двухфакторную аутентификацию!',
      },
    },
  },
}
