//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VButton from '@/components/UI/VButton';

import { V_BUTTON } from '@/utils/constants/UI';
import { STEPS } from '@/utils/constants/verification';

export default {
  name: 'TwoStepVerificationControl',
  components: {
    VButton,
  },

  props: {
    activeStepIndex: Number,
    nextIsEnabled: Boolean,
  },
  data: () => ({
    V_BUTTON,
    STEPS,
  }),
  computed: {
    isLastStep() {
      return this.activeStepIndex === STEPS.FIFTH;
    },
    buttonLabel() {
      return this.isLastStep
        ? this.$t('Finish')
        : this.$t('Continue');
    },
  },

  methods: {
    prevStep() {
      if (this.activeStepIndex !== STEPS.FIRST) {
        this.$emit('prevStep', this.activeStepIndex - 1);
      }
    },
    nextStep() {
      if (this.activeStepIndex === STEPS.FOURTH) {
        this.$emit('sendEmailCode');
        return;
      }

      if (this.activeStepIndex !== STEPS.FIFTH) {
        this.$emit('nextStep', this.activeStepIndex + 1);
      } else {
        this.$modal.hide('TwoStepVerificationModal');
        setTimeout(() => {
          this.$notify({
            group: 'notification-success',
            title: this.$t('Success'),
            text: this.$t('Notify text'),
          });
        }, 1000);
      }

      if (this.activeStepIndex === STEPS.FIRST) {
        this.$emit('sendVerificationInfo');
      }
    },
  },

  i18n: {
    messages: {
      en: {
        'Back': 'Back',
        'Continue': 'Continue',
        'Finish': 'Finish',
        'Success': 'Success!',
        'Notify text': 'You have successfully added the second protection factor.',
      },
      ru: {
        'Back': 'Назад',
        'Continue': 'Продолжить',
        'Finish': 'Завершить',
        'Success': 'Успех!',
        'Notify text': 'Вы успешно добавили второй фактор защиты.',
      },
    },
  },
}
