//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TwoStepVerificationSecondStep',

  props: {
    verification: Object,
  },

  i18n: {
    messages: {
      en: {
        'Title': 'Scan this QR code into the Google Authenticator app',
        'Description': '*If you can\'t scan the QR code, enter the code manually into the app.',
      },
      ru: {
        'Title': 'Отсканируйте этот QR-код в  приложение Google Authenticator',
        'Description': '*Если вы не можете отсканировать QR-код, введите код вручную в приложение.',
      },
    },
  },
}
